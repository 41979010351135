<template>
  <div>
    <div class="columns has-background-white-bis mt-1">
      <div class="column">
        <TP_Filter :filters="filters" @input="onFilter" title="Filtern" />
      </div>
    </div>
    <b-table
      ref="table"
      :data="result"
      :total="total"
      :loading="loading"
      :per-page="perPage"
      :current-page="page"
      :default-sort="[sortField, sortOrder]"
      :show-header="!!result.length"
      :row-class="(row, index) => rowIsClickable(row)"
      sort-icon-size="is-small"
      hoverable
      striped
      paginated
      backend-pagination
      backend-sorting
      @cellclick="onCellClick"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <template>
        <b-table-column field="year" label="Jahr" width="120" centered numeric sortable v-slot="props">
          {{ props.row.year }}
        </b-table-column>
        <b-table-column field="cat" label="Typ" width="100" centered v-slot="props">
          {{ props.row.cat }}
        </b-table-column>
        <b-table-column field="store.id" label="ID" width="35" numeric sortable v-slot="props">{{ props.row.store.id }}</b-table-column>
        <b-table-column field="store.name" label="Apotheke" sortable v-slot="props">{{ props.row.store.name}}</b-table-column>
        <b-table-column field="store.street" label="Strasse" sortable v-slot="props">{{ props.row.store.street}}</b-table-column>
        <b-table-column field="store.city" label="Ort" v-slot="props" sortable>{{ props.row.store.city }}</b-table-column>
        <b-table-column field="auditor.familyName" label="Auditor" sortable v-slot="props">{{ props.row.auditor ? props.row.auditor.familyName : '' }}</b-table-column>
        <b-table-column field="lastMod" label="Datum" numeric v-slot="props" sortable>
          <span>{{ $dayjs(props.row.lastMod).format('DD.MM.YYYY') }}</span>
        </b-table-column>
        <b-table-column label="Status" width="35" v-slot="props">
          <div class="is-flex">
            <AuditState
              v-if="result.length"
              :state="props.row.state"
              :total="props.row.total"
              :editable="props.row.editable"
            />
          </div>
        </b-table-column>
        <b-table-column
          label=""
          width="35"
          v-slot="props"
        >
          <AuditListHasFailedDeficiencies
            v-if="showHasDeficiencies(props.row)"
            :audit="props.row"
          />
        </b-table-column>
      </template>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="sad-tear" size="is-large"></b-icon></p>
            <p>Nichts gefunden ...</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import filters from '@/mixins/filters'
import generic from '@/mixins/generic'

import AuditsService from '@/services/audits.service'
import StoresService from '@/services/stores.service'
import ApiService from '@/services/api.service'

import TP_Filter from '@/components/TP_Filter'

import AuditState from './AuditState.vue'
import AuditListHasFailedDeficiencies from './AuditListHasFailedDeficiencies.vue'

export default {
  name: 'AuditList',
  mixins: [filters, generic],
  components: {
    TP_Filter,
    AuditState,
    AuditListHasFailedDeficiencies
  },
  data () {
    return {
      result: [],
      total: 0,
      loading: false,
      adminFilters: [
        {
          urlKey: 'allAudits',
          title: 'Alle Audits',
          multiple: false,
          options: [
            { id: 'true', title: 'Ja' },
            { id: 'false', title: 'Nein' }
          ],
          type: 'Inline',
          setUrlKey: false
        },
        {
          urlKey: 'apoIds',
          title: 'Apotheken',
          options: [],
          type: 'Inline',
          setUrlKey: false
        }
      ],
      filters: [
        {
          urlKey: 'year',
          title: 'Jahr',
          options: this.generateYearsBetween(2020).map((el) => ({ id: el, title: el })),
          type: 'Inline',
          multiple: false,
          select: true,
          setUrlKey: false
        },
        {
          urlKey: 'categories',
          title: 'Typ',
          options: [
            { id: 'A', title: 'Audit' },
            { id: 'C', title: 'Audit-Coaching' },
            { id: 'S', title: 'Selbstevaluation' }
          ],
          type: 'Inline',
          setUrlKey: false
        }
      ]
    }
  },
  watch: {
    isOnline () {
      this.loadData()
    },
    impersonated () {
      if (this.impersonated) {
        this.filters = this.filters.filter(e => !['allAudits', 'apoIds'].includes(e.urlKey))
      } else {
        this.filters = this.filters.concat(this.adminFilters)
      }
      this.loadData()
    },
    '$route.query': {
      immediate: false,
      handler () {
        this.checkUrlNotSameAsLocalArgs()
      }
    }
  },
  computed: {
    ...mapGetters({
      isOnline: 'global/isOnline',
      page: 'audits/page',
      getPerPage: 'audits/perPage',
      sortOrder: 'audits/sortOrder',
      sortField: 'audits/sortField',
      filterType: 'audits/filterType',
      filterString: 'audits/filterString',
      tableUrlArgs: 'audits/tableUrlArgs',
      impersonated: 'auth/impersonated',
      hasOneOfRoles: 'auth/hasOneOfRoles'
    }),
    perPage: {
      get () {
        return this.getPerPage
      },
      set (perPage) {
        this.setPerPage(perPage)
      }
    }
  },
  methods: {
    ...mapMutations({
      setPage: 'audits/setPage',
      setPerPage: 'audits/setPerPage',
      setSortOrder: 'audits/setSortOrder',
      setSortField: 'audits/setSortField',
      setFilterType: 'audits/setFilterType',
      setFilterString: 'audits/setFilterString'
    }),
    showHasDeficiencies (audit) {
      return audit.state === 'Closed' && audit.hasFailedDeficiencies && audit.total >= 70 && audit.cat === 'A'
    },
    rowIsClickable (row) {
      return row.state === 'Closed' || ((row.state === 'Pending' || row.state === 'Running') && row.editable) ? 'is-clickable' : ''
    },
    onPageChange (page) {
      this.setPage(page)
      this.setUrlTableArgs()
    },
    onSort (field, order) {
      this.setPage(1)
      this.setSortField(field)
      this.setSortOrder(order)
      this.setUrlTableArgs()
    },
    onFilter (filter) {
      this.setPage(1)
      this.setFilterString(filter.filterString)
      this.setUrlTableArgs()
    },
    confirmRun (row) {
      var txt = `${this.$options.filters.audtCatDescr(row.cat)} ${row.year} starten?`
      this.$buefy.dialog.confirm({
        message: txt,
        cancelText: 'Nein',
        confirmText: 'Ja',
        onConfirm: () => this.changeState(row.id, 'Running')
      })
    },
    onCellClick (row, col, rowIndex, colIndex) {
      if (colIndex === 8 && row.hasFailedDeficiencies) {
        return
      }
      switch (row.state) {
        case 'Pending':
          if (row.editable) {
            this.confirmRun(row)
          }
          break
        case 'Running':
          if (row.cat !== 'S' && !this.hasOneOfRoles(['QmsAuditor', 'QmsAdmin'])) {
            break
          }
          this.$router.push({
            name: row.editable ? 'AuditEdit' : 'AuditSummary',
            params: { id: row.id }
          })
          break
        case 'Closed':
          this.$router.push({
            name: 'AuditSummary',
            params: { id: row.id }
          })
      }
    },
    async getApos () {
      const response = await StoresService.getStores()
      this.adminFilters[1].options = response.map(e => ({ id: e.id, title: e.name }))
      this.filters = this.filters.concat(this.adminFilters)
    },
    async changeState (auditId, newState) {
      await ApiService.post(`audits/${auditId}/state`, {
        newState: newState
      })
      this.$router.push({ name: 'AuditEdit', params: { id: auditId } })
    },
    async loadData () {
      this.loading = true
      try {
        const response = await AuditsService.getAudits(this.tableUrlArgs)
        this.result = response.data
        this.total = response.total
      } catch (error) {
        this.result = []
        this.total = 0
      }
      this.loading = false
    },
    setUrlTableArgs () {
      if (window.location.search !== this.tableUrlArgs) {
        this.$router.push({ path: this.tableUrlArgs })
      }
    },
    parseUrlArgs () {
      const searchParams = new URLSearchParams(window.location.search)
      this.setPage(parseInt(searchParams.get('page')))
      this.setPerPage(parseInt(searchParams.get('perPage')))
      const sort = searchParams.get('sort')
      if (sort) {
        this.setSortField(sort.split(' ')[0])
        this.setSortOrder(sort.split(' ')[1])
      }
    },
    checkUrlNotSameAsLocalArgs () {
      if (this.tableUrlArgs !== window.location.search) {
        if (window.location.search) {
          this.parseUrlArgs()
          this.loadData()
          this.$nextTick(() => this.$refs.table.initSort())
        } else {
          this.$router.replace({ path: this.tableUrlArgs })
        }
      } else {
        this.loadData()
      }
    }
  },
  mounted () {
    if (this.hasOneOfRoles(['QmsAdmin'])) {
      this.getApos()
    }
    this.checkUrlNotSameAsLocalArgs()
  }
}
</script>
