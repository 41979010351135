export default {
  methods: {
    generateYearsBetween (startYear = 2021, endYear) {
      const endDate = endYear || new Date().getFullYear()
      const years = []
      for (var i = startYear; i <= endDate; i++) {
        years.push(startYear)
        startYear++
      }
      return years
    }
  }
}
