<template>
  <div>
    <Modal v-if="showModal" @close="showModal = false">
      <template #header>
        <h1 class="is-size-5 has-text-weight-bold">Mängelkriterien</h1>
      </template>
      <template #body>
        <p>
          Dieses {{ audit.cat === 'A' ? 'Audit' : 'Selbstevaluation' }} wurde mit Mängelkriterien abgeschlossen.<br>
          <template v-if="!audit.failedDeficienciesClearedAt">
            Sie können vom Auditor als behoben markiert werden.
            <div v-if="audit.auditor.sub == user.sub" class="mt-4">
              <b-button @click="setCleared" :disabled="pending" type="is-primary">Mängelkriterien als behoben markieren</b-button>
            </div>
          </template>
          <template v-else>
            Sie wurden am <b>{{ $dayjs(audit.failedDeficienciesClearedAt).format('DD.MM.YYYY') }}</b> als behoben markiert.
          </template>
        </p>
      </template>
      <template #footer>
        <b-button
          @click="showModal = false"
          class="ml-2"
        >
          Schliessen
        </b-button>
      </template>
    </Modal>

    <div @click="showModal = true">
      <b-icon
        icon="exclamation-triangle"
        type="is-danger"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ApiService from '@/services/api.service'

import Modal from './Modal.vue'

export default {
  props: {
    audit: {
      required: true,
      type: Object
    }
  },
  components: {
    Modal
  },
  data () {
    return {
      showModal: false,
      pending: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    async setCleared () {
      this.pending = true
      await ApiService.patch(`audits/${this.audit.id}`, [
        {
          op: 'replace',
          value: true,
          path: 'FailedDeficienciesCleared'
        }
      ])
      this.audit.failedDeficienciesClearedAt = this.$dayjs().format('YYYY-MM-DD')
      this.pending = false
    }
  }
}
</script>
