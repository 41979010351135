<template>
  <div>
    <AuditList />
  </div>
</template>

<script>
import AuditList from '@/components/AuditList.vue'

export default {
  components: {
    AuditList
  }
}
</script>
