<template>
  <div class="has-background-gray">
    <div
      v-if="displayBlock"
      class="mb-3 is-clickable"
      @click="showFilters = !showFilters"
    >
      <div class="is-flex">
        <a><b>{{ title }}</b></a>
        <b-icon
          :icon="`${showFilters ? 'chevron-up' : 'chevron-down'}`"
          size="is-small"
          class="ml-2 pt-2 has-text-primary"
        />
      </div>
      <span class="has-text-grey"><b>{{ filterCount }}</b> Filter selektiert</span>
    </div>
    <transition name="slide">
      <div
        :class="{ 'is-grouped is-grouped-multiline': !displayBlock }"
        v-if="showFilters"
        class="field"
      >
        <div v-if="!displayBlock && title" class="mr-2"><small>{{ title }}</small></div>
        <component
          v-for="(filter, index) in filters"
          :key="index"
          :is="`Filter${filter.type || 'Default'}`"
          v-bind="filter"
          @input="input"
          :ref="`filterRef`"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import FilterInline from './variants/FilterInline'

export default {
  props: {
    filters: {
      required: true,
      type: Array
    },
    filterString: {
      required: false,
      default: '',
      type: String
    },
    title: {
      required: false,
      default: '',
      type: String
    },
    displayBlock: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      showFilters: !this.displayBlock,
      activeFilters: {},
      activeItems: {},
      filterCount: 0
    }
  },
  components: {
    FilterInline
  },
  mounted () {
    this.filters.forEach((e) => {
      if (e.defaultChosen) {
        this.activeFilters[e.urlKey] = e.defaultChosen
      }
    })
  },
  methods: {
    urlFilterString () {
      let f = ''
      for (const key in this.activeFilters) {
        f += `${key}=${this.activeFilters[key].map((f) => f.id).join(',')}&`
      }
      return f
    },
    input (e) {
      if (e.chosen.length) {
        this.activeFilters[e.urlKey] = e.chosen
        this.activeItems[e.urlKey] = e.chosenItems
      } else {
        delete this.activeFilters[e.urlKey]
        delete this.activeItems[e.urlKey]
      }
      this.filterCount = Object.keys(this.activeFilters).length
      if (e.emit) {
        this.$emit('input', {
          filterString: this.urlFilterString(),
          active: this.activeFilters,
          activeItems: this.activeItems
        })
      }
    }
  }
}
</script>
