import ApiService from './api.service'

import store from '../store'

const StoresService = {

  async getStores () {
    const isOnline = store.getters['global/isOnline']
    if (isOnline) {
      const response = await ApiService.get('dashboard/stores', { baseURL: process.env.VUE_APP_CRM_URL })
      return response
    }
  }
}

export default StoresService
