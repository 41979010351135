<template>
  <div :class="[size, { divider: hasDivider }]" class="tag">
    <span>{{ item.title }}</span>
    <div
      @click="remove"
      class="is-flex is-align-items-center ml-2 is-clickable"
    >
      <b-icon icon="times" class="mt-1 has-text-grey-light" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    hasDivider: {
      required: true,
      type: Boolean
    },
    size: {
      required: false,
      default: 'is-small',
      type: String
    }
  },
  methods: {
    remove () {
      this.$emit('removeItem', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.divider {
  border-left: 1px solid $grey-light;
}
</style>
