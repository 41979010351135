<template>
  <div class="control">
    <div class="tags has-addons">
      <span
        :class="[chosen.length ? 'is-primary': 'has-background-primary-light has-text-grey',]"
        @click.prevent="$refs.dropdown.toggle()"
        class="tag is-clickable"
      >
        {{ title }}
      </span>
      <template v-if="chosen.length">
        <FilterItem
          v-for="(c, index) in chosen"
          :key="c.id"
          :item="c"
          :hasDivider="index > 0 && index < chosen.length"
          @removeItem="removeIt"
        />
      </template>
    </div>
    <b-dropdown
      aria-role="list"
      :close-on-click="false"
      ref="dropdown"
      class="tag-dropdown"
      scrollable
      max-height="250px"
    >
      <template>
        <div v-if="!select">
          <b-dropdown-item
              v-for="(option, index) in myOptions"
              :key="index"
              aria-role="listitem"
              class="mr-4"
            >
              <b-field>
                <b-checkbox
                  v-if="multiple"
                  v-model="myChosen"
                  :native-value="option"
                  size="is-small"
                >
                  {{ option.title }}
                </b-checkbox>
                <b-radio
                  v-else-if="!multiple && !select"
                  v-model="myChosen"
                  :native-value="option"
                  size="is-small"
                >
                  {{ option.title }}
                </b-radio>
              </b-field>
          </b-dropdown-item>
        </div>
        <div v-else>
          <b-dropdown-item
            aria-role="listitem"
            class="mr-4 pr-4"
          >
            <b-select
              placeholder="wählen"
              size="is-small"
              v-model="myChosen"
              expanded
            >
              <option
                v-for="(option, index) in myOptions"
                :key="index"
                :value="option"
              >
                {{ option.title }}
              </option>
            </b-select>
          </b-dropdown-item>
        </div>

        <div class="mt-1 pl-4 pr-4">
          <b-button
            size="is-small"
            type="is-primary"
            expanded
            @click="setChosen"
          >
            Filter setzen
          </b-button>
        </div>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import mixins from '../../mixins'

import FilterItem from './components/FilterItem'

export default {
  props: {
    apiEndpoint: {
      required: false,
      default: '',
      type: String
    },
    options: {
      required: false,
      default: () => [],
      type: Array
    },
    type: {
      required: false,
      default: 'DefaultFilter',
      type: String
    },
    title: {
      required: true,
      type: String
    },
    urlKey: {
      required: false,
      default: '',
      type: String
    },
    setUrlKey: {
      required: false,
      default: true,
      type: Boolean
    },
    multiple: {
      required: false,
      default: true,
      type: Boolean
    },
    select: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  mixins: [mixins],
  components: {
    FilterItem
  },
  data () {
    return {
      chosen: [],
      myChosen: null,
      myOptions: this.options
    }
  },
  mounted () {
    if (this.myOptions.length) {
      this.setChosenFilters(this.myOptions)
      this.myChosen = cloneDeep(this.chosen)
    }
  },
  watch: {
    '$route.query' () {
      this.setChosenFilters(this.myOptions)
      this.myChosen = this.chosen
    }
  },
  methods: {
    removeIt (t) {
      this.removeItem(t)
      this.setQueryKey()
      this.emitChosen()
    },
    setChosen () {
      this.chosen = this.multiple ? this.myChosen : [this.myChosen]
      this.setQueryKey()
      this.$refs.dropdown.toggle()
      this.emitChosen()
    },
    emitChosen () {
      this.$emit('input', {
        chosen: this.chosen,
        urlKey: this.urlKey,
        emit: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.control {
  margin-bottom: 0 !important;

  .tags {
    margin-bottom: 0;
  }

  .tag-dropdown {
    display: inline;
  }
}
label {
  width: 100%;
}
a.dropdown-item {
  padding-right: 0;
}
</style>
