import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
  methods: {
    setQueryKey () {
      if (!this.setUrlKey) return
      const q = cloneDeep(this.$route.query)
      let f = null
      if (this.chosen.length) {
        f = this.chosen.map(e => e.id).join(',')
        q[this.urlKey] = f
      } else {
        delete q[this.urlKey]
      }
      if (!isEqual(this.$route.query, q)) {
        this.$router.push({ query: q })
      }
    },
    removeItem (f) {
      this.chosen.splice(this.chosen.findIndex(e => e.id === f.id), 1)
      this.myChosen = this.chosen.length ? this.chosen : this.multiple ? [] : null
    },
    setChosenFilters (options, defaultChosen = []) {
      const queryKey = this.$route.query[this.urlKey]
      const filterIds = queryKey ? queryKey.split(',') : this.filters ? this.filters[this.urlKey] ? this.filters[this.urlKey].split(',') : null : null
      if (filterIds && filterIds.length) {
        this.chosen = options && options.length ? options.filter(o => {
          const f = filterIds.find((k) => {
            return o.id === (isNaN(k) ? k : parseInt(k))
          })
          return o.id === (isNaN(f) ? f : parseInt(f))
        }) : filterIds.map(e => { return { id: e } })
      } else {
        this.chosen = defaultChosen.length ? defaultChosen : []
        if (this.chosen.length) {
          this.setQueryKey()
        }
      }
      this.myChosen = cloneDeep(this.chosen)
      this.$emit('input', {
        chosen: this.chosen,
        urlKey: this.urlKey
      })
    }
  }
}
